import { colors } from '@/shared/tokens/colors.stylex';

export const SortArrowsIcon = () => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.94188 4.57036L6.21022 0.0960542C6.1034 -0.0320181 5.89773 -0.0320181 5.78978 0.0960542L2.05812 4.57036C1.91948 4.7372 2.04448 4.9816 2.26833 4.9816H9.73167C9.95552 4.9816 10.0805 4.7372 9.94188 4.57036Z"
        fill={colors.tertiaryBackground}
      />
      <path
        d="M9.73167 7.0184H2.26833C2.04448 7.0184 1.91948 7.2628 2.05812 7.42964L5.78978 11.9039C5.89659 12.032 6.10227 12.032 6.21022 11.9039L9.94188 7.42964C10.0805 7.2628 9.95552 7.0184 9.73167 7.0184Z"
        fill={colors.tertiaryBackground}
      />
    </svg>
  );
};
