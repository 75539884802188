import React, { useState } from 'react';
import { pageConfig } from '../model/mock-employer-page';
import { renderComponent } from '../../../shared/libs/dynamic-components-render';

export const EmployerPage = () => {
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);

  const handleToggleExpand = (id: string): void => {
    if (expandedCardId === id) {
      setExpandedCardId(null);
      return;
    }
    setExpandedCardId(id);
  };

  return (
    <>
      {pageConfig(expandedCardId, handleToggleExpand).map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </>
  );
};
