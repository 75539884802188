import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  tableRow: {
    display: 'grid',
    width: '100%',
    borderBottom: `1px solid ${colors.tertiaryBorder}`,
    ':hover': {
      backgroundColor: colors.ghostBackground,
      cursor: 'pointer',
    },
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    color: colors.primaryText,
    fontWeight: 400,
    padding: '12px',
    '@media (min-width: 1024px)': {
      fontSize: '1.17rem',
      lineHeight: '1.95rem',
      padding: '1.17rem',
    },
  },
  tableCellUnitMeasure: {
    color: colors.tertiaryBackground,
    marginLeft: '2px',
    '@media (min-width: 1024px)': {
      marginLeft: '0.19rem',
    },
  },
  tableCellImg: {
    width: '40px',
    height: '32px',
    objectFit: 'cover',

    '@media (min-width: 1024px)': {
      width: '3.9rem',
      height: '3.12rem',
    },
  },

  tableCellIcons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
    '@media (min-width: 1024px)': {
      gap: '0.39rem',
    },
  },
  icon: {
    width: '28px',
    height: '28px',

    '@media (min-width: 1024px)': {
      width: '2.34rem',
      height: '2.34rem',
    },
  },
});
