import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  headerGrid: {
    display: 'grid',
    width: '100%',
    backgroundColor: colors.brandGhostBackground,
  },
  tableHeader: {
    fontSize: '12px',
    lineHeight: '12px',
    fontStyle: 'normal',
    color: colors.tertiaryForeground,
    fontWeight: 400,
    textAlign: 'left',
    gap: '8px',
    padding: '8px 20px 8px 12px',
    '@media (min-width: 1024px)': {
      fontSize: '1.17rem',
      lineHeight: '1.17rem',
      gap: '0.78rem',
      padding: '0.78rem 1.95rem 0.78rem 1.17rem',
    },
  },

  tableHeaderWithIcons: {
    fontSize: '12px',
    lineHeight: '12px',
    fontStyle: 'normal',
    color: colors.tertiaryForeground,
    fontWeight: 400,
    textAlign: 'left',
    gap: '4px',
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'flex-start',
    ':hover': {
      cursor: 'pointer',
    },
    '@media (min-width: 1024px)': {
      fontSize: '1.17rem',
      lineHeight: '1.17rem',
      gap: '0.2rem',
      padding: '0.78rem 1.17rem',
    },
  },

  icon: {
    width: '12px',
    height: '12px',

    '@media (min-width: 1024px)': {
      width: '1.17rem',
      height: '1.17rem',
    },
  },
});
