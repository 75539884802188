import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ColumnItem } from './mock-columns';

interface HousingSelectorHeaderProps {
  columns: ColumnItem[];
  gridTemplateColumns: string;
}
export const HousingSelectorHeader = ({ columns, gridTemplateColumns }: HousingSelectorHeaderProps) => {
  const sortHandler = (column: ColumnItem) => {
    if (!column.sortable) return;
  };

  return (
    <div {...stylex.props(styles.headerGrid)} style={{ gridTemplateColumns }}>
      {columns.map(
        (column) =>
          !column.hidden && (
            <div
              {...stylex.props(column.sortable ? styles.tableHeaderWithIcons : styles.tableHeader)}
              key={column.id}
              role={column.sortable ? 'button' : ''}
              onClick={() => sortHandler(column)}
            >
              {column.renderCell ? column.renderCell(column, styles.icon) : column.value}
            </div>
          )
      )}
    </div>
  );
};
