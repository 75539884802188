import { ReactNode, useState } from 'react';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ZoomInIcon } from '../../icons/zoom-in-icon';

interface PopoverZoomInProps {
  children: ReactNode;
  handleClick?: (e: React.MouseEvent) => void;
}

export const PopoverZoomIn: React.FC<PopoverZoomInProps> = ({ children, handleClick }) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const handleMouseEnter = () => {
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  return (
    <div
      {...stylex.props(styles.container)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      role="button"
    >
      {children}
      {isPopoverVisible && (
        <div {...stylex.props(styles.popover)}>
          <ZoomInIcon />
        </div>
      )}
    </div>
  );
};
