import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  contentGrid: {
    display: 'grid',
    width: '100%',
    borderRadius: '10px',
    border: '1px solid transparent',
    overflow: 'hidden',
    backgroundColor: colors.primaryBackground,
  },
});
