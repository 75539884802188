// import { useEffect, useState } from 'react';
// import { SminexLogo } from '@/shared/ui/icons/Sminex';
// import { HeaderMenuBtn } from './header-menu-btn';
// import { HeaderOptionsBtn } from './header-options-btn';
// import { PhoneNumber } from './phone-number';
// import { Menu } from './menu';
// import styles from './header.module.css';

// export const Header = () => {
//   const [isMenuVisible, setIsMenuVisible] = useState(false);
//   useEffect(() => {
//     document.body.style.overflow = 'auto';
//     if (isMenuVisible) {
//       document.body.style.overflow = 'hidden';
//     }
//   }, [isMenuVisible]);
  

//   const handleMenuClose = () => {
//     setIsMenuVisible(false);
//   };
//   return (
//     <>
//       <div className={`${styles.headerContainer} ${isMenuVisible ? styles.clicked : ''}`}>
//         <div className={`${styles.menuWithLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
//           <HeaderMenuBtn onClickFunc={() => setIsMenuVisible(!isMenuVisible)} isMenuVisible={isMenuVisible} />
//           <div className={`${styles.sminexLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
//             <SminexLogo />
//           </div>
//         </div>
//         {!isMenuVisible && <div className={styles.headerDesc}>Коротко о нас</div>}
//         <div className={styles.optionsAndNumberBlock}>
//           <div className={`${styles.headerOptionsWrap} ${isMenuVisible ? styles.clicked : ''}`}>
//             Выбрать недвижимость
//             <HeaderOptionsBtn />
//           </div>
//           <PhoneNumber phone="+7 495 324 72 11" isMenuVisible={isMenuVisible} />
//         </div>
//       </div>
//       <div className={`${styles.menuWrapper} ${isMenuVisible ? styles.visible : styles.hidden}`}>
//         <Menu onClose={handleMenuClose}/>
//       </div>
//     </>
//   );
// };
import { useEffect, useState } from 'react';
import { SminexLogo } from '@/shared/ui/icons/Sminex';
import { HeaderMenuBtn } from './header-menu-btn';
import { HeaderOptionsBtn } from './header-options-btn';
import { PhoneNumber } from './phone-number';
import { Menu } from './menu';
import styles from './header.module.css';

export const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);

  const handleMenuClose = () => {
    setIsMenuVisible(false);
  };

  return (
    <>
      <div className={`${styles.headerContainer} ${isMenuVisible ? styles.clicked : ''}`}>
        <div className={`${styles.menuWithLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
          <HeaderMenuBtn onClickFunc={() => setIsMenuVisible(!isMenuVisible)} isMenuVisible={isMenuVisible} />
          <div className={`${styles.sminexLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            <SminexLogo />
          </div>
        </div>
        {!isMenuVisible && <div className={styles.headerDesc}>Коротко о нас</div>}
        <div className={styles.optionsAndNumberBlock}>
          <div className={`${styles.headerOptionsWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            Выбрать недвижимость
            <HeaderOptionsBtn />
          </div>
          <PhoneNumber phone="+7 495 324 72 11" isMenuVisible={isMenuVisible} />
        </div>
      </div>
      <div className={`${styles.menuWrapper} ${isMenuVisible ? styles.visible : styles.hidden}`}>
        <Menu onClose={handleMenuClose} />
      </div>
    </>
  );
};
