import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { TabSwitch } from '../tabswitch';
import { DividerIcon } from '@/shared/ui/icons/divider-icon';
import { useState } from 'react';
import { RowItem } from '@/features/data-grid/ui/body/ui/mock-data';

const tabs = ['C мебелью', 'Без размеров', 'С размерами'];

export interface ApartmentPlanProps {
  apartment: RowItem | null;
}

export const ApartmentPlan = ({ apartment }: ApartmentPlanProps) => {
  const [active, setActive] = useState('C мебелью');
  if (!apartment) return null;
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.header)}>
        <div {...stylex.props(styles.projectInfo)}>
          <div {...stylex.props(styles.projectInfoHeader)}>
            <div {...stylex.props(styles.apartmentType)}>{apartment?.type}</div>
            <div {...stylex.props(styles.apartmentDivider)}>
              <DividerIcon />
            </div>
            <div>
              <span {...stylex.props(styles.apartmentSizeText)}>{apartment?.square}</span>
              <span {...stylex.props(styles.apartmentSizeText, styles.apartmentSizeTextMeasure)}>
                {apartment?.measure}
              </span>
            </div>
          </div>
          <div {...stylex.props(styles.priceContainer)}>
            <span {...stylex.props(styles.projectPrice)}>{apartment?.cost}</span>
            <span {...stylex.props(styles.projectPrice, styles.projectPriceMeasure)}>{apartment?.fullCurrency}</span>
          </div>
        </div>
        <div {...stylex.props(styles.projectName)}>{apartment?.project}</div>
      </div>
      <div {...stylex.props(styles.bodyWrapper)}>
        <div {...stylex.props(styles.body)}>
          <img src={apartment?.schemaSrc} {...stylex.props(styles.image)}></img>
        </div>
      </div>
      <div {...stylex.props(styles.footer)}>
        <TabSwitch tabs={tabs} active={active} setActive={setActive} />
      </div>
    </div>
  );
};
