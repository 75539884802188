import { EmployerPage } from '@/pages/employer-page';
import { MainLayout } from '@/app/main-layout';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { TestPage } from '@/pages/test-page';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainLayout />}>
        <Route path="/best-employer" element={<EmployerPage />} />
      </Route>
      <Route path="test" element={<TestPage />} />
    </Route>
  )
);
