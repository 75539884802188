import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface TabSwitchProps {
  tabs: string[];
  active: string;
  setActive: (tab: string) => void;
}

export const TabSwitch = ({ tabs, active, setActive }: TabSwitchProps) => {
  const handleClick = (tab: string) => {
    setActive(tab);
  };
  return (
    <div {...stylex.props(styles.container)}>
      {tabs.map((tab) => (
        <div {...stylex.props(styles.tab(tab === active))} key={tab} onClick={() => handleClick(tab)} role="button">
          {tab}
        </div>
      ))}
    </div>
  );
};
