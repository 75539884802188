import { Footer } from '@/features/footer';
import { Header } from '@/features/header';
import { Outlet } from 'react-router-dom';

export const MainLayout = () => {
  return (
    <>
        <Header />
        <Outlet />
        <Footer />
    </>
  );
};
