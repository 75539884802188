import apartmentplan from '@shared/ui/assets/apartment-plan.png';

export interface RowItem {
  id: number;
  project: string;
  schemaSrc: string;
  bedrooms: number;
  square: string;
  size: string;
  type: string;
  floor: string;
  building: string;
  number: string;
  priceForSquare: string;
  cost: string;
  measure: string;
  currency: string;
  fullCost: string;
  fullCurrency: string;
  isAddToCompare: boolean;
  isAddToFavs: boolean;
}

export const rows: RowItem[] = [
  {
    id: 1,
    project: 'Малая Ордынка 19',
    schemaSrc: apartmentplan,
    bedrooms: 3,
    square: '172',
    size: 'M',
    type: 'Вилла',
    floor: '32',
    building: '2',
    number: '777',
    priceForSquare: '555 000',
    measure: 'м²',
    currency: '₽',
    cost: '23,45',
    fullCurrency: 'млн. ₽',
    fullCost: '23 450 000',
    isAddToCompare: true,
    isAddToFavs: false,
  },
  {
    id: 2,
    project: 'Обыденский №1',
    schemaSrc: apartmentplan,
    bedrooms: 4,
    square: '27',
    size: 'S',
    type: 'Апартаменты',
    floor: '8',
    building: '1',
    number: '42',
    priceForSquare: '368 000',
    measure: 'м²',
    currency: '₽',
    cost: '32,56',
    fullCurrency: 'млн. ₽',
    fullCost: '32 560 000',
    isAddToCompare: true,
    isAddToFavs: false,
  },
  {
    id: 3,
    project: 'Тишинский бульвар',
    schemaSrc: apartmentplan,
    bedrooms: 4,
    square: '289',
    size: 'XXL',
    type: 'Вилла',
    floor: '1',
    building: '',
    number: '52',
    priceForSquare: '905 000',
    measure: 'м²',
    currency: '₽',
    cost: '112,43',
    fullCurrency: 'млн. ₽',
    fullCost: '112 430 000',
    isAddToCompare: true,
    isAddToFavs: false,
  },
];
