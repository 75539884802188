import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    padding: '4px',
    alignItems: 'flex-start',
    gap: '4px',
    borderRadius: '8px',
    border: `1px solid ${colors.tertiaryBorder}`,
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      gap: '2px',
    },
  },
  tab: (isActive) => ({
    display: 'flex',
    padding: '8px 20px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: isActive ? colors.primaryInverseForeground : colors.primaryInverseBackground,
    backgroundColor: isActive ? colors.primaryForeground : colors.primaryInverseForeground,
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    '@media (max-width: 768px)': {
      padding: '6px 12px',
      fontSize: '12px',
      lineHeight: '12px',
    },
  }),
});
