import { ReactNode } from 'react';
import { DzenIcon } from '../ui/icons/soc-media/DzenIcon';
import { HhIcon } from '../ui/icons/soc-media/HhIcon';
import { TelegIcon } from '../ui/icons/soc-media/TelegIcon';
import { VKIcon } from '../ui/icons/soc-media/VKIcon';
import { WhatsAppIcon } from '../ui/icons/soc-media/WhatsAppIcon';
import { YouTubeIcon } from '../ui/icons/soc-media/YouTubeIcon';

interface SocMediaType {
  id: number;
  title: string;
  icon: ReactNode;
}

export const menuContent = [
  {
    id: 0,
    title: 'FINE DEVELOPMENT',
  },
  {
    id: 1,
    title: 'ОТ ПРОЕКТА К РЕАЛЬНОСТИ',
  },
  {
    id: 2,
    title: 'ПОРТФОЛИО',
  },
  {
    id: 3,
    title: 'СЛУЖБА КОМФОРТА',
  },
  {
    id: 4,
    title: 'СКОРО В ПРОДАЖЕ',
    children: [{ id: 5, title: 'Фрунзенская набережная',url:'' }],
  },
  {
    id: 6,
    title: 'Информация',
    children: [
      { id: 7, title: 'Работодатель №1',url:'best-employer' },
      { id: 8, title: 'Информационный центр',url:'' },
      { id: 9, title: 'Кабинет брокера',url:'' },
      { id: 10, title: 'Тендеры и сотрудничество',url:'' },
      { id: 11, title: 'Контакты',url:'contacts' },
    ],
  },
];

export const menuSocMedia: SocMediaType[] = [
  {
    id: 0,
    title: 'WhatsApp',
    icon: <WhatsAppIcon />,
  },
  {
    id: 1,
    title: 'VK',
    icon: <VKIcon />,
  },
  {
    id: 2,
    title: 'Telegram',
    icon: <TelegIcon />,
  },
  {
    id: 3,
    title: 'Dzen',
    icon: <DzenIcon />,
  },
  {
    id: 4,
    title: 'YouTub',
    icon: <YouTubeIcon />,
  },
  {
    id: 5,
    title: 'Hh',
    icon: <HhIcon />,
  },
];

export const navSections = [
  'Ценности компании',
  'Компания мечты',
  'Сотрудники мечты',
  'Правление',
  'Вакансии',
];