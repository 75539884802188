import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { RowItem } from './mock-data';
import { ApartmentPlan } from '@/features/apartment-plan';
import { useState } from 'react';
import { Modal } from '@/shared/ui/modal';
import { Row } from './row';

interface HousingSelectorBodyProps {
  rows: RowItem[];
  gridTemplateColumns: string;
}

export const HousingSelectorBody = ({ rows, gridTemplateColumns }: HousingSelectorBodyProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState<RowItem | null>(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedApartment(null);
  };

  return (
    <div {...stylex.props(styles.contentGrid)}>
      {rows.map((row) => (
        <Row
          key={row.id}
          row={row}
          gridTemplateColumns={gridTemplateColumns}
          setIsModalOpen={setIsModalOpen}
          setSelectedApartment={setSelectedApartment}
        />
      ))}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} isFullScreen={false}>
        <ApartmentPlan apartment={selectedApartment} />
      </Modal>
    </div>
  );
};
