export const DividerIcon = () => {
  return (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5655 2.816C0.5655 1.472 1.6695 0.368 3.0135 0.368C4.3575 0.368 5.4615 1.472 5.4615 2.816C5.4615 4.16 4.3575 5.264 3.0135 5.264C1.6695 5.264 0.5655 4.16 0.5655 2.816Z"
        fill="#8E9296"
      />
    </svg>
  );
};
