import stylex from '@stylexjs/stylex';
import { RowItem } from '../../mock-data';
import { styles } from './styles';
import { renderFullCost, renderImg, renderPriceForSquare, renderSquare, renderTableActions } from '../../render-props';

export interface IRowProps {
  row: RowItem;
  gridTemplateColumns: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setSelectedApartment: (selectedApartment: RowItem | null) => void;
}

export const Row = ({ row, gridTemplateColumns, setIsModalOpen, setSelectedApartment }: IRowProps) => {
  const clickHandler = (e: React.MouseEvent, row: RowItem) => {
    e.stopPropagation();
    setIsModalOpen(true);
    setSelectedApartment(row);
  };
  return (
    <div
      key={row.id}
      {...stylex.props(styles.tableRow)}
      role="button"
      onClick={() => {
        console.log('999');
      }}
      style={{ gridTemplateColumns }}
    >
      <div {...stylex.props(styles.tableCell)}>{row.project}</div>
      <div {...stylex.props(styles.tableCell)}>{renderImg(row, styles.tableCellImg, (e) => clickHandler(e, row))}</div>
      <div {...stylex.props(styles.tableCell)}>{row.bedrooms}</div>
      <div {...stylex.props(styles.tableCell)}>{renderSquare(row, styles.tableCellUnitMeasure)}</div>
      <div {...stylex.props(styles.tableCell)}>{row.size}</div>
      <div {...stylex.props(styles.tableCell)}>{row.type}</div>
      <div {...stylex.props(styles.tableCell)}>{row.floor}</div>
      <div {...stylex.props(styles.tableCell)}>{row.building ? row.building : '-'}</div>
      <div {...stylex.props(styles.tableCell)}>{row.number}</div>
      <div {...stylex.props(styles.tableCell)}>{renderPriceForSquare(row, styles.tableCellUnitMeasure)}</div>
      <div {...stylex.props(styles.tableCell)}>{renderFullCost(row, styles.tableCellUnitMeasure)}</div>
      <div {...stylex.props(styles.tableCell, styles.tableCellIcons)}>{renderTableActions(row, styles.icon)}</div>
    </div>
  );
};
