import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
    gap: '10px',
    borderRadius: '12px',
    fontFamily: fonts.din2014Light,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1500px',
    maxHeight: '1500px',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
      gap: '8px',
      textAlign: 'center',
    },
  },
  bodyWrapper: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '12px',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },

  projectInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
    gap: '8px',
  },
  apartmentType: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '32px',
    color: colors.primaryForeground,
  },
  apartmentDivider: { display: 'flex', alignItems: 'center', justifyContent: 'center' },

  apartmentSizeText: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '32px',
  },
  apartmentSizeTextMeasure: {
    color: colors.tertiaryForeground,
    marginLeft: '4px',
  },

  projectInfoHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    gridColumn: 1 / 2,
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  priceContainer: {
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  projectPrice: { fontSize: '18px', fontStyle: 'normal', fontWeight: '600', lineHeight: '24px' },
  projectPriceMeasure: {
    marginLeft: '4px',
    color: colors.tertiaryForeground,
  },
  projectName: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '36px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: colors.primaryForeground,
    gridColumn: 2 / 3,
    '@media (max-width: 768px)': {
      marginBottom: '12px',
      textAlign: 'center',
    },
  },
  footer: {
    display: 'flex',
    padding: '4px',
    alignItems: 'flex-start',
    gap: '4px',
    marginTop: 'auto',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
    },
  },
});
